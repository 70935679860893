import React,{ useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import PostTime from '../PostTime';


function Dashboard() {
  const navigate = useNavigate();

  useEffect(()=>{
    if(localStorage.getItem('token') === "" || localStorage.getItem('token') == null){
        navigate("/");
    }
  },[])

    const logoutAction = () => {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('auth')
      localStorage.removeItem('uJas1wHAcv')
      localStorage.removeItem('pM2KdeqasF')
      navigate("/");
      }

    return (
        <Layout>
           <div className="row justify-content-md-center">
                <div className="col-12">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container d-block">
                            {/* <a className="navbar-brand" href="#">Dashboard</a> */}
                            <div className="d-flex justify-content-between">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                      <span className="nav-link">
                                        Welcome,&nbsp;
                                        <b>
                                          {localStorage.getItem("user")}
                                        </b>
                                      </span>
                                    </li>
                                </ul>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a onClick={()=>logoutAction()} className="nav-link " aria-current="page" href="#/">Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <PostTime/>
        </Layout>
    );
}

export default Dashboard;