import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { HashRouter , Routes, Route } from "react-router-dom"
import './App.css';
import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"

// import GetIssues from './GetIssues';
// import PostTime from './PostTime';
function PageNotFound() {  return (    <div>      <h2>404 Page not found</h2>    </div>  );}

function App() {
  return (
    <HashRouter basename='/'>
      <Routes>
          <Route exact path="/"  element={<Login/>} />
          <Route exact path="/dashboard"  element={<Dashboard/>} />
          <Route path="*" element={<PageNotFound />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
