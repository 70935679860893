import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import swal from 'sweetalert';


const PostTime = () => {
  let auth = btoa((CryptoJS.AES.decrypt(localStorage.getItem('uJas1wHAcv'), process.env.REACT_APP_PASS)).toString(CryptoJS.enc.Utf8) + ':' + (CryptoJS.AES.decrypt(localStorage.getItem('pM2KdeqasF'), process.env.REACT_APP_PASS)).toString(CryptoJS.enc.Utf8));

  const [time_entry, setTimeEntry] = useState({
    issue_id: '',
    hours: '',
    activity_id: '',
  });
  const [issues, setIssues] = useState([]);

  const [activity, setActivity] = useState([]);

  const getIssues = () => {
    let isMounted = true;
    axios.get(`https://rm.smashy.xyz/issues.json`, {
      mode: "cors",
      headers: {
        Authorization: `Basic ${auth}`,
        "Content-Type": "application/json",
      },
    }).then(res => {
      console.log(res);
      if (isMounted) {
        if (res.status === 200) {
          setIssues(res.data.issues)
          // console.log(issues);
        }
      }
    });

    return () => {
      isMounted = false
    }
  }

  const resetForm = () => {
    setTimeEntry({
      issue_id: '',
      hours: '',
      activity_id: '',
    });
  }

  useEffect(() => {
    getIssues();
    console.log();
  }, []);

    useEffect(() => {
      let isMounted2 = true;
      axios.get(`https://rm.smashy.xyz/enumerations/time_entry_activities.json`, {
        mode: "cors",
        headers: {
          Authorization: `Basic ${auth}`,
          "Content-Type": "application/json",
        },
      }).then(res => {
        if (isMounted2) {
          if (res.status === 200) {
            setActivity(res.data.time_entry_activities)
          }
        }
      });

      return () => {
        isMounted2 = false
      }
    }, [])

  const handleInput = (e) => {
    e.persist();
    setTimeEntry({ ...time_entry, [e.target.name]: e.target.value });
  }

  const handleIssueSelect = (item) => {
    setTimeEntry({ ...time_entry, issue_id: item.value });
  }

  const handleActivitySelect = (item) => {
    setTimeEntry({ ...time_entry, activity_id: item.value });
  }


  const selectIssueOptions = issues.map(item => {
    return ({
        value: item.id,
        label: item.subject
    });
  });

  const selectActivityOptions = activity.map(item => {
    return ({
        value: item.id,
        label: item.name
    });
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    let timeLogObject = {
      time_entry,
    }

    console.log(timeLogObject);
    setIssues([]);
    axios({
      method: "post",
      url: `https://rm.smashy.xyz/time_entries.json`,
      data: timeLogObject,
      headers: { Authorization: `Basic ${auth}` },
    }).then(res => {
        if (res.status === 201) {
          swal("Success", "Timelog Added Successfully", "success");
          resetForm();
          getIssues();
          setIssues(issues);
        } else {
          swal("Success", "Timelog Failed", "warning");
        }
    });
  }

  return (
    <div className='mt-5'>
      <h1 className='text-center'>Add Time Logs</h1>
      <form onSubmit={handleSubmit}>
        <Select
          className="issue-select"
          classNamePrefix="select"
          value={selectIssueOptions.filter(function(option) {
              return option.value === time_entry.issue_id;
          })}
          isSearchable
          onChange={handleIssueSelect}
          name="issue_id"
          options={selectIssueOptions}
        />
        <Select
          className="activity-select"
          classNamePrefix="select"
          value={selectActivityOptions.filter(function(option) {
              return option.value === time_entry.activity_id;
          })}
          // isSearchable
          onChange={handleActivitySelect}
          name="activity_id"
          options={selectActivityOptions}
        />
        <input
          type="number"
          className="spent-hours"
          placeholder="Time Log in Hours"
          name = "hours"
          value={time_entry.hours}
          onChange={handleInput}
        />
        <button type="submit">Add Timelog</button>
      </form>


      <div className='mb-5 mt-5'>
      <h1 className='text-center'>Issues</h1>
      <div>
        <table>
          <thead>
            <tr>
              <th>Project ID/Name</th>
              <th>Issue ID/Title</th>
              <th>Estimated Hours</th>
              <th>Spent Hours</th>
              <th>Due date</th>
              <th>Assigned to</th>
            </tr>
          </thead>
          {issues.map(issue => (
            <tbody>
              <tr>
                <td>{issue.project.id}/{issue.project.name}</td>
                <td>{issue.id}/{issue.subject}</td>
                <td>{issue.estimated_hours ? issue.estimated_hours : "N/A"}</td>
                <td>{(Math.round(issue.total_spent_hours*100)/100).toFixed(2)}</td>
                <td>{issue.due_date ? issue.due_date : "N/A"}</td>
                <td>{issue.assigned_to ? issue.assigned_to.name : "N/A"}</td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
    </div>
  )
}

export default PostTime;