import React,{ useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import CryptoJS from 'crypto-js';

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(()=>{
        if(localStorage.getItem('token') !== "" && localStorage.getItem('token') != null){
            navigate("/dashboard");
        }
    },[])

    const loginAction = (e) => {
        setValidationErrors({})
        e.preventDefault();
        setIsSubmitting(true)

        let payload = {
            method: "get",
            url: `/my/account.json`,
            headers: {
              Authorization: `Basic ${btoa(`${username}:${password}`)}`,
            },
        }
        axios(payload)
        .then((res) => {
            console.log(res);
            setIsSubmitting(false)
            console.log(res);
            localStorage.setItem('token', res.data.user.api_key)
            localStorage.setItem('admin', res.data.user.admin)
            localStorage.setItem('user', res.data.user.firstname+' '+res.data.user.lastname)
            localStorage.setItem('uJas1wHAcv', CryptoJS.AES.encrypt(username, process.env.REACT_APP_PASS).toString())
            localStorage.setItem('pM2KdeqasF', CryptoJS.AES.encrypt(password, process.env.REACT_APP_PASS).toString())
            navigate("/dashboard");
        })
        .catch((e) => {
            setIsSubmitting(false)
        });
    }

    return (
        <Layout>
            <div className="row justify-content-md-center mt-5">
                <div className="col-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title mb-4">Sign In</h5>
                            <form onSubmit={(e)=>{loginAction(e)}}>
                                {Object.keys(validationErrors).length !== 0 &&
                                     <p className='text-center '><small className='text-danger'>Incorrect Email or Password</small></p>
                                }

                                <div className="mb-3">
                                    <label
                                        htmlFor="email"
                                        className="form-label">
                                            Email address
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        name="username"
                                        value={username}
                                        onChange={(e)=>{setUsername(e.target.value)}}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="password"
                                        className="form-label">Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e)=>{setPassword(e.target.value)}}
                                    />
                                </div>
                                <div className="d-grid gap-2">
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="btn btn-primary btn-block">Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Login;